import React from 'react'
import { HighlightInit } from '@highlight-run/next/highlight-init'

interface HighlightIoProps {
  highlightId?: string
}
export const HighlightIo = ({ highlightId }: HighlightIoProps) =>
  highlightId?.length ? (
    <HighlightInit
      projectId={highlightId}
      tracingOrigins
      networkRecording={{
        enabled: true,
        recordHeadersAndBody: true,
        urlBlocklist: [],
      }}
    />
  ) : null
