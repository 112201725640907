import React, { useMemo } from 'react'
import dynamic from 'next/dynamic'
import { HighlightIo } from '@/components/atoms/highlight-io'
import ConsentManager from '@/components/consent-manager'
import { ThemeStyle } from '@/components/theme-style'
import { SiteContextProvider } from '@/lib/context'
import { useTabbing } from '@/lib/use-tabbing'
import { ErrorBoundary } from '@highlight-run/react'
import { Analytics } from '@vercel/analytics/react'
import '../styles/tailwind.css'
import '../styles/app.css'

const LivePreview = dynamic(() => import('@/components/live-preview'))

const Site = ({ Component, pageProps }) => {
  const { data } = pageProps

  useTabbing()

  const pageID = useMemo(() => data?.page?.id, [data])

  if (data?.preview) {
    return <LivePreview {...{ pageID, Component, pageProps }} />
  }

  return <Component key={pageID} {...pageProps} />
}

// Site wrapped with Context Providers
const MyApp = ({ Component, pageProps }) => {
  const { data } = pageProps
  const consentManager = data?.site?.consentManager
  const highlightId = process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID

  return (
    <SiteContextProvider data={{ ...data?.site }}>
      <HighlightIo highlightId={highlightId} />
      <ErrorBoundary>
        <ThemeStyle {...data} />
        <ConsentManager apiKey={consentManager?.apiKey} domain={consentManager?.domain} />
        <Site Component={Component} pageProps={pageProps} />
      </ErrorBoundary>
      <Analytics />
    </SiteContextProvider>
  )
}

export default MyApp
