import { FC } from 'react'
import Script from 'next/script'

interface ConsentProps {
  apiKey?: string
  domain?: string
}

const ConsentManager: FC<ConsentProps> = ({ apiKey, domain }) => {
  if (!apiKey || !domain) {
    return null
  }

  return (
    <Script
      src={`https://cloud.ccm19.de/app.js?apiKey=${apiKey}&domain=${domain}&lang=de_DE`}
      referrerPolicy="origin"
    />
  )
}

export default ConsentManager
